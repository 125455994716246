import React from 'react'

const SubscribeForm = () => {

  return (
    <section className="subscribe-form">
      <h2>Subscribe to Receive Post Updates!</h2>
      <a className="subscribe-button" href="http://eepurl.com/hn81Y1">Subscribe!</a>
    </section>
  )
}

export default SubscribeForm
