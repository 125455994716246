import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

const MoonIcon = () => {
  return <img className="site-dark-icon" src="/images/icons/moon.svg" alt="Dark" />
}

const SunIcon = () => {
  return <img className="site-dark-icon" src="/images/icons/sun.svg" alt="Light" />
}

const DarkToggle = ({ theme, toggleTheme }) => {
  return (
    <Toggle 
      defaultChecked={theme === 'dark'}
      onChange={toggleTheme}
      icons={{
        checked: <MoonIcon />,
        unchecked: <SunIcon />
      }}
      className="site-dark-toggle"
    />
  )
}

DarkToggle.propTypes = {
  theme: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired
}

export default DarkToggle
